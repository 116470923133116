$gutter: 1rem; //for primeflex grid system

@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/prismjs/themes/prism-coy.css";
@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";

@import "assets/sass/general/general.scss";
@import 'assets/sass/variables/layout/layout_dim';
@import 'assets/sass/variables/layout/layout_light';
@import 'assets/sass/ag-tables/ag_tables';


* {
    //touch-action: manipulation;
    user-select: text;
}

body {
    touch-action: manipulation;

    &.touch-action-off {
        touch-action: auto;
    }
}

.custom-table-icon {
    font-size: 10px;
    margin-right: 5px;
}

p-toast, .p-toast, p-toastitem {
    touch-action: none !important;

    * {
        touch-action: none !important;
    }
    .p-toast-icon-close {
        touch-action: auto;
    }
}

.grid {
    margin-right: -6px;
    margin-left: -6px;
    margin-top: -6px;
}

/*
    Player's note styles start
 */
.name-surname {
    margin-left: 4px;
    color: var(--primary-color);;
}

.player-notes-icon {
    display: inline-flex;
    vertical-align: middle;
    font-size: 24px;
    cursor: pointer;

    &.view-player-notes {
        color: var(--primary-color);;
    }
}
/*
    Player's note styles end
 */

/*
    Dialog styles start
 */
.p-dialog {
    .p-dialog-footer {
        width: 100%;
        height: 56px;
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: flex-end;
        border-top: 0 none;
        padding: 10px;
        box-shadow: 0px -1px 6px rgba(0, 0, 0, 0.05);

        button {
            margin: 0 0.5rem 0 0;
            width: auto;
        }
    }

    .grid {
        margin-right: 0;
        margin-left: 0;
        margin-top: 0;
    }
}

.dialog-dropdown {
    .p-dropdown-items-wrapper, .p-multiselect-items {
        max-height: 110px !important;
    }
}

#LanguageId .p-dropdown-items-wrapper {
    max-height: 150px !important;
}

@media screen and (max-width: 991px) {
    .p-dialog {
        width: 90% !important;
    }
}

.p-overflow-hidden {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
}

/*
    Dialog styles end
 */


/*
    Calendar styles end
 */
@media only screen and (max-width: 769px) {
    p-calendar{
        left: 0 !important;
    }

    .p-datepicker td>span {
        margin: 3px auto !important;
    }
}
/*
    Calendar styles end
 */

/*
    Global styles start
 */
.link-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.link {
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
}

.dot {
    width: 8px;
    height: 8px;
    border-radius: 2px;
    // status color
    background: red;
}

.p-toolbar {
    background: transparent;
    border: none;
    padding: 0;
    margin-bottom: 1rem;
}

.no-data-found {
    width: 100%;
    height: 100%;
    font-weight: 600;
    font-size: 17.5px;
    line-height: 24px;
}
/*
    Global styles end
 */

/*
    Notes' styles start
 */
app-ggr-component,
app-notes-component,
app-comment-component,
app-notes-edit-component
{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
}

app-view-notes {
    background: inherit;
}

.view-image-dialog-hover {
    height: 115px;
    overflow: hidden;
}

.view-image-dialog {
    overflow: hidden;
}

.view-notes-dialog {
    .p-dialog-header-icons {
        z-index: 999;
    }
}
/*
    Notes' styles end
 */

/*
    Player's note styles start
 */
.name-surname {
    margin-left: 4px;
    color: var(--primary-color);;
}

.player-notes-icon {
    display: inline-flex;
    vertical-align: middle;
    font-size: 24px;
    cursor: pointer;

    &.view-player-notes {
        color: var(--primary-color);
    }
}
.parent-hover-block {
    .icon-image
    {
        display: inline-flex;
        vertical-align: middle;
        color: var(--primary-color);
    }
}
/*
    Player's note styles end
 */


.comment-button {
    min-width: 144px;
    max-width: 144px;
    justify-content: flex-start;
}

/*
    Info pages' styles start
 */
.main-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3.5px 20px;

    .title {
        font-weight: 600;
        font-size: 14px;
        white-space: nowrap;
    }

    .action-buttons {
        display: flex;
        align-items: center;
        cursor: pointer;
        font-size: 14px;
        justify-content: space-between;

        .action-block {
            display: flex;
            align-items: center;
        }

        .icon-notes, .icon-message, .icon-edit {
            padding-right: 0;
            margin-right: 0;
        }
    }
}

.detail {

    &-inner {
        height: auto;
    }

    &-key {
        line-height: 15px;
        margin-bottom: 0;
    }

    &-value {
        line-height: 18px;

        &-bg-green {
            background: rgb(121, 242, 184);
            color: #3F3F3F;
        }

        &-bg-red {
            background: rgb(255, 214, 218);
            color: #3F3F3F;
        }
    }
}

.inner-page-info-item,
.detail-inner-wrapper {
    .detail-inner {
        &:last-child {
            border-bottom: none;
        }
    }
}

.inner-page-info {

    &-bottom-section {
        margin-left: -6px;
        margin-right: -6px;
        display: none;

        .detail {
            padding-top: 10px;
            padding-bottom: 10px;

            &-inner {
                padding: 10px 0;
            }

            &-key {
                margin-bottom: 5px;
            }
        }
    }

    &-right-section {
        border-radius: 12px;
        margin-top: 15px;
        padding: 4px 12px;

        > div {
            height: 39px;
            padding: 11px 0 5px 0;

            &:not(:last-child) {
                border-bottom: 1px solid rgba(228, 229, 241, 0.4);
            }
        }

        .detail-key {
            margin-bottom: 0;
        }

        p-progressbar {
            width: 50%;
        }
    }
}

.top-section-item {
    min-width: 140px;
    max-width: 190px;
    display: flex;
    align-items: center;

    .detail-inner {
        width: 100%;
    }
}

.tabs-wrapper {
    margin-top: 9px;
    box-shadow: $contentBlockShadow;
    border-radius: 8px;

    .p-tabmenu .p-tabmenu-nav {
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;

        &-container {
            border-radius: 8px 8px 0 0;
        }

        .p-menuitem-text {
            white-space: nowrap;
        }

        &-next {
            box-shadow: -2px 0 5px rgb(0 0 0 / 8%);
            border-radius: 0 8px 0 0;
        }

        &-prev {
            box-shadow: 2px 0 5px rgb(0 0 0 / 8%);
            border-radius: 8px 0 0 0;
        }

        &-btn {
            width: 22px;
            height: 43px;
            touch-action: manipulation;

            span {
                font-size: 9px;
            }
        }

        .p-tabmenuitem {
            &.p-highlight {
                .p-menuitem-link {
                    font-weight: 600;
                    font-size: 14px;
                }
            }

            .p-menuitem-link {
                font-weight: 400;
                font-size: 14px;
                padding: 1rem 20px;

                &:not(.p-disabled):focus {
                    box-shadow: none;
                }
            }
        }
    }

    .table-wrapper {
        padding: 15px 8px;
    }
}

@media screen and (min-width: 1091px) {

    .inner-page-info {
        &-bottom-section {
            display: flex;
        }
    }

    .more-less-wrapper {
        display: none;
    }
}

@media screen and (max-width: 1090px) {
    .inner-page-info {
        &-top-section {
            padding: 0 19px;
            margin-left: -6px;
            margin-right: -6px;

            > div {
                justify-content: space-between;
            }
        }

        &-right-section {
            border: none;
            margin-top: 0;
            padding-top: 0;
            padding-bottom: 0;

            > div {
                padding-top: 5px;
            }
        }
    }

    .top-section-item {
        flex: 0 0 49%;
        min-width: 49%;
        max-width: 49%;

        &:nth-last-of-type(-n+2) {
            border-bottom: none;
        }
    }
}

.status-badge {
    cursor: default;
    height: 24px;
    border-radius: 8px;
    font-size: 13px;
    font-weight: 600;
    padding: 4.5px 12px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
}

/*
    Info pages' styles end
 */

.detail-loading {
    width: 100%;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/*
    popup Info part' styles start
 */
.info-block {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 21px 24px;

    .info-block-icon-block {
        margin-right: 10px;
    }

    .active {
        color: $surface1200;
    }

    .info-block-text-block {
        margin-left: 15px;
    }

    .info-block-first {
        font-size: 11px;
        font-weight: 700;
        text-transform: uppercase;
        margin: 0;
    }

    .info-block-second {
        font-size: 13px;
    }
}
/*
    popup Info part' styles start
 */

/*
    Icons sizes styles start
 */

.icon-size-xs {
    font-size: 16px;
}

.icon-size-sm {
    font-size: 18px;
}

.icon-size-md {
    font-size: 24px;
}

.icon-size-lg {
    font-size: 28px;
}

.icon-size-xl {
    font-size: 36px;
}

/*
   Icons sizes styles end
 */

/*
   Filters styles start
 */
.dropdown-select-size {
    width: 250px;
}

/*
   Filters styles end
 */

.upload-label {
    cursor: pointer;
    padding: 0 10px !important;
}

.upload-button {
    width: 94px;
    height: 118%;
    padding: 0 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.custom-file-input {
    display: none;
}

.upload-button span {
    margin-right: 6px;
}

.upload-button i {
    margin-left: 6px;
}

.file-input-invalid {
    border-color: #FF4444 !important;
    box-shadow: none;
}

.image-name {
    width: 100%;
    padding-right: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.setting-section-container {
    height: 100%;

    .setting-section {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: 20px;
    }
}

/*
    Status column styles start
 */
app-state-cell-component {
    p-dropdown {
        border-radius: 8px;

        .p-dropdown {
            height: 24px;
            border-radius: 8px;
            font-size: 13px;
            line-height: 1;
            display: inline-block;
            padding: 5px 20px 0 12px;
            text-align: center;
            background: inherit;
            border: none;
            margin-top: 8px;

            .p-dropdown-trigger {
                width: auto;
                position: absolute;
                right: 5px;
                top: 8px;
                margin: 0;
                color: inherit;

                .p-dropdown-trigger-icon {
                    color: inherit;
                    font-size: 8px;
                }
            }

            &:not(.p-disabled).p-focus {
                outline: none;
                box-shadow: none;
            }

            &-label {
                width: 100%;
                padding: 0;
                background: transparent;
                border: none;
                color: inherit;
                font-weight: 600;
            }
        }
    }
}
/*
    Status column styles end
 */

.disabled {
    opacity: .4;
    touch-action: none;
}

.footer-checkbox-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin: 0 0 0 13.5px;
    white-space: nowrap;
}
