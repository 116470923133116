//reusable color variables (by designer)
$surface000:#FFFFFF !default;
$surface100:#FCFCFD !default;
$surface200:#F2F2F2 !default;
$surface300:#E3E3E8 !default;
$surface400:#CBCBD8 !default;
$surface500:#B3B3BC !default;
$surface600:#9898A4 !default;
$surface700:#858593 !default;
$surface800:#54545F !default;
$surface900:#343A40 !default;
$surface1000:#1B1B40 !default;
$surface1100:#F5FBFF !default;
$surface1200: #0FBF6B !default;

$CSurface000:#FFFFFF !default;
$CSurface100:#FCFCFD !default;
$CSurface200:#F2F2F2 !default;
$CSurface300:#E3E3E8 !default;
$CSurface400:#CBCBD8 !default;
$CSurface500:#B3B3BC !default;
$CSurface600:#9898A4 !default;
$CSurface700:#858593 !default;
$CSurface800:#54545F !default;
$CSurface900:#343A40 !default;
$CSurface1000:#1B1B40 !default;
$CSurface1100:#F5FBFF !default;
$CSurface1200: #0FBF6B !default;

//reusable shadow variables (by designer)
$modalShadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
$mainShadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
$contentBlockShadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
