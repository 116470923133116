//reusable color variables (by designer)
$surface000:#1F2D40 !default;
$surface100:#17212F !default;
$surface200:#1B2637 !default;
$surface300:#304562 !default;
$surface400:#5E6B7D !default;
$surface500:#89929F !default;
$surface600:#A6ACB5 !default;
$surface700:#D9DADD !default;
$surface800:#DEE0E3 !default;
$surface900:#F1F2F3 !default;
$surface1000:#FFFFFF !default;
$surface1100:#2A3C54 !default;
$surface1200: #0FBF6B !default;

$CSurface000:#1F2D40 !default;
$CSurface100:#17212F !default;
$CSurface200:#1B2637 !default;
$CSurface300:#304562 !default;
$CSurface400:#5E6B7D !default;
$CSurface500:#89929F !default;
$CSurface600:#A6ACB5 !default;
$CSurface700:#D9DADD !default;
$CSurface800:#DEE0E3 !default;
$CSurface900:#A6ACB5 !default;
$CSurface1000:#FFFFFF !default;
$CSurface1100:#2A3C54 !default;
$CSurface1200: #0FBF6B !default;

//reusable shadow variables (by designer)
$modalShadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
$mainShadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
$contentBlockShadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
